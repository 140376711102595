.banner .carousel-item {
  height: 85vh;
}

.banner .carousel-item img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  /* min-height: 720px; */
  height: 100%;
}

.banner .carousel-control-next,
.carousel-control-prev {
  position: absolute !important;
  bottom: 100px !important;
  top: 75% !important;
  z-index: 2 !important;
  width: 50px !important;
  height: 50px;
  padding: 0 !important;
  color: #fff !important;
  text-align: center !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border: 0 !important;
  opacity: 1 !important;
  transition: opacity 0.15s ease !important;
}

.banner .carousel-control-next:hover,
.banner .carousel-control-prev:hover {
  background-color: #fe9d00 !important;
}

.banner .carousel-control-prev {
  left: 90%;
}

.banner .carousel-control-next {
  right: 4% !important;
}

.banner .carousel-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.banner .carousel-caption {
  z-index: 1;
}

.banner .carousel-caption {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: left;
}

.banner .carousel-item img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.two {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.three {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.slider-content {
  margin-top: 100px;
}

.slider-content h5 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.slider-content h5::after {
  content: "";
  height: 2px;
  width: 55px;
  position: relative;
  display: inline-block;
  background: #fe9d00;
  top: -5px;
  left: 15px;
}

.slider-content h2 {
  font-size: 60px;
  letter-spacing: 1px;
  line-height: 1.1;
  margin-bottom: 20px;
  color: #fff;
}

.s-slider-content p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin: auto;
  padding-right: 60px;
}

.slider-btn {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 30px;
}

.btn {
  border-radius: 4px !important;
  color: #fff !important;
  display: inline-block !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 1 !important;
  margin-bottom: 0 !important;
  padding: 20px 30px !important;
  text-align: center !important;
  text-transform: unset !important;
  touch-action: manipulation !important;
  transition: all 0.3s ease 0s !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  position: relative !important;
  z-index: 1 !important;
  text-transform: capitalize !important;
  background: #fe9d00 !important;
  overflow: hidden !important;
}

.btn::before {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  content: "";
  background: #125875;
  top: -60px;
  left: 10px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btn:hover::before {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  content: "";
  background: #125875;
  top: 0;
  left: 0;
  z-index: -1;
}

.btn svg {
  margin-left: 10px;
  font-size: 18px;
}

.btn-pulse {
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: 0.5s;
  font-weight: 600;
  overflow: hidden;
  background: #fe9d00;
}

.btn-pulse span {
  position: absolute;
  display: block;
}

.btn-pulse span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, transparent, #fff);
  animation: animate1 1s linear infinite;
}

@keyframes animate1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.btn-pulse span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #fff);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes animate2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.btn-pulse span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(270deg, transparent, #fff);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes animate3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.btn-pulse span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #fff);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

@media (max-width: 1680px) {
  .banner .carousel-control-prev {
    left: 89%;
  }

  /* .slider-content {
    margin-top: 40px;
  } */

  .banner .carousel-control-next,
  .carousel-control-prev {
    top: 67% !important;
  }
}

@media (max-width: 1440px) {
  .banner .carousel-control-prev {
    left: 88%;
  }
}

@media (max-width: 1366px) {
  .slider-content h2 {
    font-size: 65px;
  }
}

@media (max-width: 1200px) {
  .banner .carousel-control-prev {
    left: 87%;
  }
}

@media (max-width: 1024px) {
  .slider-content h2 {
    font-size: 53px;
  }
}

@media (max-width: 991px) {
  .banner .carousel-item {
    height: 650px;
  }

  .banner .carousel-item img {
    min-height: 650px;
  }

  .banner .carousel-control-prev {
    left: 85%;
  }
}

@media (max-width: 800px) {
  .banner .carousel-control-prev {
    left: 82%;
  }
}

@media (max-width: 600px) {
  .banner .carousel-control-prev {
    left: 78%;
  }
}

@media (max-width: 480px) {
  .slider-content {
    margin-top: 50px;
  }

  .slider-content h2 {
    font-size: 42px;
  }

  .banner .carousel-item {
    height: 500px;
  }

  .banner .carousel-item img {
    min-height: 500px;
  }

  .banner .carousel-control-prev {
    left: 73%;
  }
}

@media (max-width: 430px) {
  .banner .carousel-control-prev {
    left: 75%;
  }

  .banner .carousel-control-next-icon,
  .banner .carousel-control-prev-icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .banner .carousel-control-next,
  .carousel-control-prev {
    width: 40px !important;
    height: 40px;
  }

  .slider-content h5 {
    font-size: 16px;
  }

  .slider-content h2 {
    font-size: 34px;
  }

  .banner .carousel-item {
    height: 430px;
  }

  .banner .carousel-item img {
    min-height: 430px;
  }

  .btn {
    font-size: 15px;
    padding: 15px 16px;
  }
}

@media (max-width: 375px) {
  .banner .carousel-control-prev {
    left: 73%;
  }
}

@media (max-width: 360px) {
  .slider-content {
    margin-top: 24px;
  }
}